/*

 Fonts
  
 Headings         MuseoSlab500
 Body copy        MuseoSans300
 Body subtitle    MuesoSans500
 Links            MuseoSans500
 Buttons          MuesoSans900

 Colors

 RED_ERROR = '#d9000c';
 WHITE = '#FFFFFF';
 BLUE = '#0482e6';
 GREEN = '#5fce17';
 FOREST_GREEN = '#004d40';
 YELLOW = '#fbd740';
 DARK_GREY = '#414141';
 MID_GREY = '#888888';
 WHITE_GREY = '#f2f2f2';
 BORDER_GREY = '#cccccc';
 LIGHT_GREY = '#b7b7b7';
 ORANGE = '#fbb040';
 UNICORN_PURPLE = '#9c27b0';
 BROWNIE = '#8d6e63';
 RED_ROOSTER = '#e82248';
 ROYAL_BLUE = '#1a2373';
 SKY_BLUE = '#a7d8ff';
 MINTY_GREEN = '#e6fde2';


 HIGHLIGHT_GREEN_DARK = '#9de56e';
 HIGHLIGHT_GREEN_LIGHT = '#b8e09d';
 HIGHLIGHT_BLUE_DARK = '#2196f3';
 HIGHLIGHT_BLUE_LIGHT = '#2299ff';
 HIGHLIGHT_YELLOW_DARK = '#ffeb3b';
 HIGHLIGHT_YELLOW_LIGHT = '#feea3a';
 HIGHLIGHT_ORANGE_DARK = '#fac053';
 HIGHLIGHT_ORANGE_LIGHT = '#f9bf53';
 HIGHLIGHT_UNICORN_PURPLE_DARK = '#9c4c9d';
 HIGHLIGHT_UNICORN_PURPLE_LIGHT = '#b833ce';
 HIGHLIGHT_BROWNIE_DARK = '#ba9c92';
 HIGHLIGHT_BROWNIE_LIGHT = '#bb9d93';
 HIGHLIGHT_RED_ROOSTER_DARK = '#ee2649';
 HIGHLIGHT_RED_ROOSTER_LIGHT = '#ff1744';

*/

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

/*********** CALL TO ACTION BANNER ********/

.call-to-action-banner {
    margin: 0 -420px;
    padding: 45px 0;
    background: #f0f0f0 no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.call-to-action-banner > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.call-to-action-banner .download_block , .call-to-action-banner .online_republic_container_col{
    display: inline-block;
    margin: 15px;
}

.call-to-action-banner .download_block .frosted-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (min-width: 1200px) {

    .call-to-action-banner .download_block {
        min-height: 350px;
    }

    .call-to-action-banner > div {
        justify-content: center;
        flex-direction: row;
    }
    .call-to-action-banner .download_block .frosted-box {
        /*flex-direction: row;*/
    }
}

.call-to-action-banner .online_republic_container_col .online_republic_container {
    margin: 0;
}

.call-to-action-page-end .call-to-action-banner-buttons .btn {
    margin: 0;
}

.frosted-box {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    padding: 10px 30px 10px;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}

.call-to-action-banner .frosted-box {
    max-width: 800px;
    margin: 0 auto;
}

.call-to-action-banner h2 {
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    color: #555555;
    text-align: center;
}

.call-to-action-banner ul {
    text-align: center;
    list-style: none;
}

.call-to-action-banner ul li {
    font-weight: 500;
    color: #888;
}

.call-to-action-banner-buttons {
    display: block;
    text-align: center;
}

.call-to-action-banner-buttons a {
    /*margin: 0 10px;*/
}

.call-to-action-banner-buttons a:hover {
    /*text-decoration: none;*/
}

.call-to-action-banner-buttons .btn {
    display: block !important;
    margin: 45px auto;
    font-size: 24px;
}

@media (max-width: 600px) {
    .call-to-action-banner {
        padding: 0 0 0;
    }

    .call-to-action-banner h2 {
        text-align: center !important;
    }

    .call-to-action-banner ul {
        text-align: center;
        list-style-position: inside;
        padding-left: 0;
    }

    .call-to-action-banner-buttons {
        display: none;
    }
}

/*********** MAP MARKER ***********/

.map-marker {
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
}

.map-marker .map-marker-label {
    position: absolute;
    bottom: 15px;
    width: 150px;
    background: #ffffff;
    z-index: 99999;
    border-radius: 5px;
    font-size: 13px;
    left: -75px;

}

.map-marker .map-marker-label-image {
    height: 100px !important;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

.map-marker .map-marker-label-image img {
    height: 120%;
    width: auto;

}

.map-marker .map-marker-label-text {
    padding: 10px 10px;
}

/************** HOST BANNER **********/

.host-banner {
    margin: -40px -400px 0;
    padding: 80px 0 280px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.host-banner h1 {
    color: #fff;
    font-size: 44px;
}

.host-banner h4 {
    color: #fff;
}

.host-banner .btn {
    display: block !important;
    margin-top: 25px;
    font-size: 22px;
}

.cta-become-a-host {
    text-align: center;
}

/*********** HOMEPAGE TEMPLATE *********/

#homepage .navbar-default {
    background: transparent;
    border-bottom: none;
}

#homepage .navbar-default .navbar-nav > li > a {
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    letter-spacing: 0.6px;
}

#homepage h2 {
    text-align: center;
}

#homepage .footer-container {
    padding-top: 25px;
}

#homepage hr {
    display: none;
}

/********* STANDARD PAGE TEMPLATE **********/

.page {
    margin-bottom: 45px;
}

.page-content {
    padding-top: 34px;
    max-width: 1400px;
    width: 100%;
}

.main-container {
    width: 100%;
}

.standard-page ol {
    padding-left: 20px;
}

.standard-page .image-block {
    padding: 20px;
}

.standard-page .list-block.col-md-4 {
    padding-bottom: 20px;
}

/*********** HEADER *********/

.header {
    background-color: #fff
}

.header-bar {
    padding: 10px;
}

.header .logo {
    width: 160px;
    display: block;
    margin: 20px 4px;
}

.header-logout {
    text-align: right;
    margin-top: 20px;
}

.home-banner {
    background-size: cover;
    background-color: #f1f1f1;
    padding: 80px 0 108px;
    margin-top: -90px;
}

.home-banner-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 30px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

.home-banner h1 {
    font-size: 62px;
    color: #fff;
    margin-bottom: 60px;
    font-family: "Museo Slab", "museo-slab", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

.home-banner h1 strong {
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
}

.home-banner input {
    border-radius: 10px;
    font-size: 24px;
    height: 74px;

    color: #555555;
}

.home-banner .form-control {
    border-color: #ffffff;
    border: none;
}

.home-banner .form-control:focus {
    cursor: text;
    border-color: #ffffff;
    border: none;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 1px 1px 5px rgba(0, 0, 0, 0);
    border-radius: 10px;
}

.home-banner .search-bar {
    background: #fff;
    border-radius: 10px;
    position: relative;
}

.home-banner .search-bar .search-field {
    width: 98%;
    margin-left: 01%;
    margin-right: 01%;
    display: inline-block;
}

.home-banner .search-icon img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.home-banner .btn {
    font-size: 22px;
    position: absolute;
    top: 13px;
    right: 10px;
}

.pac-item {
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    padding: 0 10px;
    line-height: 50px;
    text-align: left;
    border-top: 1px solid #e1e1e1;
    font-size: 13px;
    color: #888;
}

.pac-item-query {
    font-size: 18px;
    padding-right: 3px;
    color: #555;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.pac-matched {
    font-weight: 700;
}

.pac-icon-marker {
    background-position: -22px -183px;
}

.pac-icon {
    width: 5px;
    height: 34px;
    margin-right: 0;
    margin-top: 0;
    display: inline-block;
    vertical-align: top;
    background-image: url('resources/images/green-pin-campervan.png');
    background-size: 23px;
    background-repeat: no-repeat;
}

/************ COLLECTIONS ********/

#homepage .contentful-row {
    margin: 55px 0 20px;
}

#homepage .overwrite-margin {
    margin: 0 0;
}

.contentful-row {
    margin: 0 0 45px;
}

.contentful-column {
}

.collection-block {

}

.collection-block h4 {
    margin: 0 0 15px;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.collection-block .collection-tile {
    padding-bottom: 15px;

}

.collection-block-button {
    text-align: center;
}

/************ LOCATIONS ************/

#locations-map {
    margin-bottom: 0;
    border-bottom: 1px solid #cccccc;
}

#locations-map .location-tiles {
    overflow: scroll;
    padding: 2px 16px;
    padding-left: 22px;
    height: 94%;
}

.modal-backdrop.in {
    opacity: 0.8;
}

.location-container-left {
    height: 92vh;

}

/*.locations-location-tile {*/
/*margin-bottom: 12px;*/
/*padding-left: 6px;*/
/*padding-right: 6px;*/
/*}*/

.tags-container {
    margin-left: -16px;
    margin-right: -16px;
    z-index: 9999;
    border-top: solid 1px #e1e1e1;
    justify-content: flex-start;
    text-align: match-parent;
}

.location-filter-container {
    padding: 11px 0 9px 22px;
}

.tags-container .filter-button {
    display: inline-block;
    padding: 5px 20px;
    margin-right: 2px;
}

.tags-container .filter-chip {
    display: inline-block;
    margin-right: 2px;
}

.tags-container .filter-chip > a {
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    font-size: 12px;
    background: #e1e1e1;
    font-weight: 500;
    color: #555;
}

.tags-container .filter-chip a > a {
    margin: 0 2px;
    text-decoration: none;
}

.cls-1 {
    opacity: 0.078;
}

.cls-2 {
    fill: #5fce17;
    stroke: #ffffff;
    stroke-width: 2px;
}

.cls-3 {
    stroke: none;
}

.cls-4 {
    fill: none;
}

/************ LOCATION COLLECTIONS ********/

.location-tile {
    background: #ffffff;
    border-radius: 5px;
}

.location-tile-active {
    background: #e6fde2;
    border-radius: 5px;
}

.tile:hover {
    -webkit-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.11);
}

.location-tile a {
    text-decoration: none;
}

.location-tile-image {
    overflow: hidden;
    width: auto;
    height: 150px;
    position: relative;
    border-radius: 5px 5px 0 0;
    text-align: center;
    background-size: cover;
}

.location-tile-image img {
    width: 100%;
}

.locations-map .location-tile-image img {
    width: auto;
    height: 120%;
}

.location-tile-price {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 10px;
    padding: 5px 5px 7px 3px;
    background: #e6fde2;
    border-radius: 5px 5px 0 0;
    border: #5fce17 solid 1px;
    border-bottom: none;
    color: #5fce17;
    font-weight: 900;
    font-size: 19px;
}

.price-dollar-symbol {
    font-size: 12px;
    vertical-align: text-top;
}

.location-tile-description {
    padding: 12px 17px;
    min-height: 90px;
}

.location-tile-description h3 {
    color: #44A8F7;
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0px 6px;
    overflow: hidden;
}

.location-tile-description p {
    color: #b7b7b7;
    font-size: 12px;
}

/*********** LIST BLOCK ********/

.list-block .description {
    text-align: center;
    max-width: 800px;
}

.list-block h4 {
    font-size: 15px;
}

.list-block img {
    width: 50px;
    height: 50px;
    vertical-align: top;
}

.list-block-button {
    text-align: center;
    padding: 20px 0;

}

.list-block-button a {
    font-size: 18px;
}

.list-block-button a:after {
    content: url('resources/images/blue-arrow-link.svg');
    margin: 0 0 0 7px;
    text-decoration: none;
    display: inline-block;
    width: 7px;
}

/************* Collection listing block/items ***************/

.collection-listings-item {
    width: 240px;
    height: 300px;
}

.collection-listings-image img {
    width: 100%;
    height: 100%;
    vertical-align: top;
}

.collection-listings-item h4 {
    font-size: 12px;

}

.ratings img {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-right: 1px;

}

/************* IMAGE BLOCK *********/

.image-block {
    text-align: center;
}

.image-block .two-phones-with-campable {
    width: 60%;
}

.image-block-with-button {
    height: 200px;
    overflow: hidden;
    margin-bottom: 15px;
}

/********** LOGIN SCREEN ******/

.landing {

}

.landing .logo {
    height: 30%;
    text-align: center;
    margin: 100px 0 70px;
}

.landing .logo .img {
    max-width: 400px;
    width: 90%;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

.login-box {
    background: #fff;
    border: #cccccc solid 1px;
    border-radius: 5px;
    margin: 0 auto;
    padding: 22px 36px;
    max-width: 500px;
    min-width: 280px;
    -webkit-box-shadow: 3px 3px 14px 1px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 3px 3px 14px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 3px 3px 14px 1px rgba(0, 0, 0, 0.11);
    z-index: 9999;
}

.login-box .form-control {
    max-width: 100%;
}

.login-box .btn {
    width: 200px;
    margin-bottom: 15px;
}

.landing .login-box h4 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
    margin: 20px 0;
}

.login-options {
    text-align: center;
    padding: 30px 0 20px;
}

.login-options-dividing-line {
    width: 100%;
    height: 12px;
    border-bottom: 1px solid #cccccc;
    text-align: center;
    margin: 10px 0 33px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

.login-box .login-options-dividing-line span {
    font-size: 12px;
    background-color: #fff;
    padding: 0 10px;
    color: #666666;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

.landing .handle-submit {
    width: 100% !important;
}

.back-button {
    display: block;
    text-align: center;
}

.landing .button-container {
    text-align: center;
    justify-content: center;
}

.landing .button-container a {
    width: 100%;
    text-align: center;
}

.landing .button-container .bottom-container {
    padding: 0 0 10px 0;
}

.landing .button-container .bottom-container p {
    color: #666666 !important;
    background-color: #000;
}

.privacy-container {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.privacy-checkbox {
    display: inline-block;
    padding: 0 2px;
}

.bottom-container p {
    color: #666666;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

.auth-error {
    background-color: #FFE6E6;
    padding: 10px;
    border-radius: 8px;
    color: #d9000c;
    font-weight: 500;
    text-align: center;
}

/*********** ACCOUNT **********/

/*********** BOOKINGS *********/

.host-bookings-panel {
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 23px 20px 0px 20px;
    margin-left: 15px;
}

.host-bookings-panel .table > tbody > tr > td {
    border-top: none;
    padding-top: 2px;
    padding-bottom: 4px;
    -webkit-font-smoothing: initial;
}

.host-transactions-controller .host_next_payout {
    padding: 18px;
    margin-bottom: 18px;
    border: 1px solid #e1e1e1;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.11);
    width: fit-content;
    border-radius: 5px;
}

.host-transactions-controller .host_next_payout > span {
    font-weight: bold;
}

.host-transactions-controller .table > tbody > tr {
    border-bottom: 1px solid #e1e1e1;
}

.host-transactions-container .card {
    padding: 12px 0;
}

.host-transactions-controller .table > thead {
    border-bottom: 1px solid #e1e1e1;
}

.host-transactions-controller .table > thead > tr > th {
    padding-bottom: 15px;
}

.host-transactions-controller .table > tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.host-transactions-controller .pager {
    padding: 0 15px;
}

.host-transactions-controller .pager li {
    user-select: none;
}

.host-transactions-controller .pager li a {
    user-select: none;
    outline: none;
}

.host-payouts-controller .table > tbody > tr {
    border-bottom: 1px solid #e1e1e1;
}

.host-payouts-container .card {
    padding: 12px 0;
}

.host-payouts-controller .table > thead {
    border-bottom: 1px solid #e1e1e1;
}

.host-payouts-controller .table > thead > th {
    padding-bottom: 15px;
}

.host-payouts-controller .table > tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.table > thead:first-child > tr:first-child > th {
    font-weight: 700;
}

.transaction-date {
    font-weight: 500;
    color: #555;
}

.transaction-type {
    font-weight: 500;
    color: #555;
}

.transaction-type-fee {
    font-weight: 300;
    color: #888;
    -webkit-font-smoothing: initial;

}

.transaction-total {
    font-weight: 500;
    color: #555;
    text-align: right;
    padding-right: 10px;
}

.transaction-total-fee {
    font-weight: 300;
    color: #888;
    -webkit-font-smoothing: initial;
}

.host-bookings-panel .booking-cancel-row {
    padding-bottom: 10px !important;
    text-align: right;
}

.host-bookings-panel .booking-cancel-row a {
    color: #888;
    font-weight: 300;
}

.host-bookings-panel .booking-cancel-row a:before {
    background-image: url('resources/images/cross-grey.png');
    background-repeat: no-repeat;
    background-size: 88%;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
    margin-left: 0px;
    position: relative;
    top: 7px;
    margin-right: 2px;
    opacity: 1;
    margin-bottom: 3px;
}

.host-bookings-panel .booking-cancel-row span {
    color: #d9000c;
}

.host-bookings-panel .booking-cancel-row p {
    color: #5fce17;
}

.host-transactions-controller card .host-booking-row {
    border-bottom: 1px solid #cccccc;
    padding: 10px 0;
}

.host-booking-row-last {
    border-bottom: none !important;
}

.host-bookings-panel .table-responsive {
    margin-top: -9px !important;
}

.host-bookings-row p {
    margin: 0;
}

.host-bookings-row-header {
    color: #555666;
    font-weight: 500;
    font-size: 12px;
}

.booking-date-box {
    width: 150px;
    border: 1px solid #e2e2e2;
    padding: 6px 20px;
    margin: 13px 10px 13px 0;
    display: inline-block;
}

.directions-box {

    border: 1px solid #e2e2e2;
    padding: 6px 20px;
    margin: 13px 10px 13px 0;

}

.filter {
    padding: 20px 15px 25px;
}

.filter .input-group {
    width: 100%;
}

.row-with-margin {
    margin: 0px 0 10px;
}

.page-menu {
    padding-left: 0;
    padding-top: 9px;
}

.page-menu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.page-menu li a {
    color: #555;
    text-decoration: none;
    font-weight: 300;
    line-height: 3rem;
    padding-left: 0;
    -webkit-font-smoothing: initial;
}

/*********** LABEL *********/

.label {
    background-color: #fff;
    border-radius: 50px;
    padding: 8px 18px 7px;
    border: 1px solid #9DE56E;
    color: #5fce17;
}

.label a {
    color: #5fce17;
    font-size: 14px;
    font-family: "Museo Slab", "museo-slab", Helvetica, Arial, sans-serif;
    font-weight: 900;
}

.label a:hover {
    color: #5fce17;
}

/*********** LOCATION HEADER *********/

.location-header {
    background-color: #004D40;
    padding: 12px 10px 9px 30px;
    border-radius: 5px 5px 0 0;
}

.location-header img {
    width: 70px;
}

.location-header .form-name {
    width: 80%;
    display: inline-block;
}

.location-header .dropDown {
    width: 20%;
    display: inline-block;
}

.location-header .form-name .form-group {
    margin-bottom: 0;
}

.location-header input {
    margin: 10px 0;
    color: white;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 21px;
    background: transparent;
    border: none;
    padding-left: 0;
    webkit-transition: padding 0.2s;
    transition: padding 0.2s;

}

.location-header input:hover {
    border-bottom: 1px solid;
    position: relative;
    top: 1px;
}

.location-header input:focus {
    padding-left: 3px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    position: relative;
    top: 1px;
}

.location-header input::-webkit-input-placeholder {
    color: #ffffff;
}

.location-header input::-moz-placeholder {
    color: #ffffff;
}

.location-header input:-ms-input-placeholder {
    color: #ffffff;
}

.location-header input:-moz-placeholder {
    color: #ffffff;
}

.location-header .help-block {
    display: none;
}

.location-header .has-error .form-control {
    border: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

.location-header .location-unlisted {
    color: #d9000c;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    margin-bottom: 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #FFE5EA;
    padding: 12px 25px 14px;
    border: 1px solid #FFBCC9;
    display: inline-block;
}

.location-header .location-unlisted {

}

/*********** LOCATION DETAILS *********/

.location-detail-view .input-row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.location-detail-view label {
    display: inline-block;
    width: 20%;
    text-align: right;
    padding-right: 25px;
}

.location-detail-view input {
    display: inline-block;
    width: 80%;
}

.location-detail-view textarea {
    display: inline-block;
    width: 80%;
}

.location-detail-view textarea {
    vertical-align: middle;
    cursor: text;
    border: #cccccc 1px solid;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 1px 1px 5px rgba(0, 0, 0, 0);
    border-radius: 5px;
}

.location-header label {
    display: none;
}

.location-detail-view .checkbox-group {
    width: 80%;
    display: inline-block;
    vertical-align: top;
}

.location-detail-view .checkbox label {
    width: 100%;
    text-align: left;
}

.location-images {
    margin-bottom: 20px;
    border-radius: 0 0 5px 5px;
}

.location-map {
}

.location-map h4 {
    display: flex;
    z-index: 999999;
    top: -50px;
    position: absolute;
}

.location-marker {
    fill: #5fce17;
}

.location-map-container hr {
    margin-top: 30px;
}

.location-address-form-control {
    font-size: 13px;
    background-color: #e7e7e7;
    background-image: none;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 10px 10px;
    height: 40px;
    text-indent: 4px;
}

.location-address-form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    -webkit-box-shadow: 0 0 8px rgba(102, 175, 233, 0.6) 1px 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 8px rgba(102, 175, 233, 0.6) 1px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6) 1px 20px rgba(0, 0, 0, 0.2);
}

.location-unlisted {
    color: #d9000c;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    margin-bottom: 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #FFE5EA;
    padding: 12px 25px 14px;
    border: 1px solid #FFBCC9;
}

.location-features ul {
    list-style: none;
    padding-left: 0;
}

.location-features li:before {
    background-image: url('resources/images/features-default.svg');
    background-repeat: no-repeat;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
    margin-left: 0px;
    position: relative;
    top: 4px;
    margin-right: 7px;
    opacity: 0.8;
    margin-bottom: 3px;
}

.location-features li {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0px;
    -webkit-font-smoothing: initial;
    line-height: 1.7rem;
}

.location-features .water:before {
    background-image: url('resources/images/features-kettle.svg');
}

.location-features .shower:before {
    background-image: url('resources/images/features_shower.svg');
}

.location-features .toilet:before {
    background-image: url('resources/images/features-toilet.svg');
}

.location-features .laundry:before {
    background-image: url('resources/images/features_laundry.svg');
}

.location-features .wifi:before {
    background-image: url('resources/images/features_wifi.svg');
}

.location-features .fire:before {
    background-image: url('resources/images/features_fire.svg');
}

.location-features .cell:before {
    background-image: url('resources/images/features_no-coverage.svg');
}

.location-features .non_self_contained:before {
    background-image: url('resources/images/features_campervan.svg');
}

.location-features .pets:before {
    background-image: url('resources/images/features_pets.svg');
}

.location-features .power:before {
    background-image: url('resources/images/features_power.svg');
}

.location-features .private:before {
    background-image: url('resources/images/features_private.svg');
}

.location-features hr {
    margin-top: 36px;
}

.location-booking-information .booking-information-container {
    margin-bottom: 21px;
}

.location-booking-information .booking-info-white-area {
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px 10px;
}

.date-picker-container {
    display: flex;
    justify-content: flex-end;
}

.date-picker-container .CalendarDay--selected-start, .CalendarDay--selected-end, .CalendarDay--selected {
    background-color: #5fce17 !important;
    border-color: #9DE56E !important;
}

.CalendarDay--selected-start {
    background-color: #5fce17 !important;
    border-color: #9DE56E !important;
}

.CalendarDay--hovered-span {
    background-color: #9DE56E !important;
    border-color: #5fce17 !important;
}

.CalendarDay--selected-span {
    background-color: #9DE56E !important;
    border-color: #5fce17 !important;
}

.DateInput__display-text--focused {
    background-color: #5fce17 !important;;
    border-color: #5fce17 !important;
    color: white !important;;
}

.location-booking-information .booking-info-white-area img {
    width: 75px;
    margin-right: 14px;
}

.location-booking-information .booking-info-textbox {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
}

.location-booking-information .booking-info-white-area h4 {
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    margin-bottom: 2px;
}

.location-booking-information .booking-info-white-area p {
    font-weight: 300;
    color: #888888;
    font-size: 14px;
    margin-bottom: 0;
}

.location-booking-information .booking-info-white-area .location-tile-price {
    right: 16px;
    font-size: 26px;
}

.location-booking-information .booking-info-white-area .price-dollar-symbol {
    font-size: 15px;
}

.location-booking-information .booking-info-green-area {
    background: #e6fde2;
    border-top: 1px solid #5fce17;
    border-radius: 0 0 5px 5px;
    padding: 17px 20px;
}

.location-booking-information .booking-info-green-area .booking-info-textbox {
    width: 80%;
}

.location-booking-information .booking-info-green-area img {
    width: 35px;
    margin-right: 10px;
}

.location-booking-information .booking-info-green-area a {
    margin-right: 10px;
}

/*********** CAMPSITE *********/

.validated-text-input {
    margin-bottom: 15px;
}

.campsite-container {
    margin-bottom: 50px;
}

.campsites-h2 {
    display: inline-block;
}

.inline-card h4 {
    margin-bottom: 30px;
}

.link-property-vs-campsite {
    margin-left: 10px;
}

.campsite-header {
    background-color: #5fce17;
    padding: 12px 10px 9px 30px;
    border-radius: 5px 5px 0 0;
}

.campsite-header input::-webkit-input-placeholder {
    color: #ffffff;
}

.campsite-header input::-moz-placeholder {
    color: #ffffff;
}

.campsite-header input:-ms-input-placeholder {
    color: #ffffff;
}

.campsite-header input:-moz-placeholder {
    color: #ffffff;
}

.campsite-header img {
    width: 60px;
    margin-right: 10px;
}

.campsite-header .form-name {
    width: 80%;
    display: inline-block;
}

.campsite-header .form-name .form-group {
    margin-bottom: 0;
}

.campsite-header input {
    margin: 10px 0;
    color: white;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 24px;
    background: transparent;
    border: none;
    padding-left: 0;
    webkit-transition: padding 0.2s;
    transition: padding 0.2s;

}

.campsite-header input:hover {
    border-bottom: 1px solid;
    position: relative;
    top: 1px;
}

.campsite-header input:focus {
    padding-left: 3px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    position: relative;
    top: 1px;
}

.campsite-header label {
    display: none;
}

.campsite-header .help-block {
    display: none;
}

.campsite-header .has-error .form-control {
    border: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

.location-campsite {
    border-radius: 0 0 5px 5px;
    position: relative;
    padding-bottom: 100px !important;
}

.campsite-price {
    margin-bottom: 40px;
}

.campsite-features {
    margin-top: 50px;
}

.remove-campsite {
    display: block;
    margin: 0 -50px;
    margin-left: -30px;
    text-align: center;
    border-top: #cccccc solid 1px;
    padding: 20px 0 23px;
    background: #f1f1f1;
    border-radius: 0 0 5px 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.remove-campsite a {
    color: #888888;
}

/*********** LOCATION *********/

.location-description-block {
    width: 90%;
    display: inline-block;
}

.location-detail {

}

.location-detail-view {

}

.location-body-text {
    max-height: 75px;
    overflow: hidden;
}

.location-body-text-more {
    height: auto;
}

/************* LOCATION **********/

.nav-pills {
    padding-top: 5px;
    border-bottom: 1px solid #cccccc;
}

.nav-pills > li a {
    color: #888888;
}

.nav > li > a:focus, .nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
    color: #5fce17;
    background-color: transparent;
    border-bottom: 2px solid #5fce17;
    border-radius: 0;
}

.location-container label {
    font-size: 15px;
    font-weight: 300;
    display: block;
    margin-bottom: 5px;
    color: #555;
    -webkit-font-smoothing: initial;
}

.location-container h1 {
    margin: 56px 0 20px;
}

.location-container h3 {
    margin-bottom: 22px;
}

.location-details hr {
    margin-top: 37px;
    margin-bottom: 26px;
}

.location-description {
    background-color: #fff;
    padding: 20px 8px 50px;
    border: solid 1px #cccccc;
    border-top: none;
}

.location-description .description {
    margin-bottom: 30px;
}

.locations-header-details {
    padding-left: 6px;
}

.location-header .input-group .form-control {
    width: 80%;
}

.location-campsite .form-image-input {
    margin-bottom: 40px;
}

.profilePhoto {
    text-align: center;
}

.profilePhoto .form-image-input label {
    vertical-align: top;
    margin: auto;
}

.location-campsite .form-image-input label {
    vertical-align: top;
    margin-top: 12px;
}

.location-campsite .form-image-input .image-input {
    width: 80%;
    display: inline-block;
}

.profilePhoto .form-image-input .image-input {
    width: 80%;
    display: inline-block;
    text-align: center;
}

.profilePhoto .form-image-input .image-input-image-circle {
    text-align: center;
}

.location-campsite .image-input {
    width: 80%;
    display: inline-block;
}

.location-panel {
    background-color: #fff;
    margin-bottom: 9px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 25px 10px;
    position: relative;
}

.bookings-controller-container .location-panel {
    padding: 25px 26px;
}

.bookings-controller-container .location-panel .reviews-container {
    /*background-color: #337ab7;*/
}

.bookings-controller-container .location-panel .star-container {
    display: block;
}

.bookings-controller-container .location-panel .star-container img {
    width: 35px;
    height: 35px;
    margin: 5px;
    cursor: pointer;
}

.bookings-controller-container .location-panel .comment-container {
    display: block;
}

.bookings-controller-container .location-tile-price {
    right: 18px;
}

.location-header h3 {
    display: inline-block;
    margin: 4px 10px 2px 0;
    color: #555;
    font-size: 21px;

}

.location-header h4 {
    color: #888888;
    margin: 0 15px 13px 0;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
}

.location-panel h3 {
    color: #2196F3;
    margin: 4px 0 5px;
    font-size: 21px;
    cursor: pointer;
}

.location-panel h4 {
    color: #888;
    margin: 0 15px 13px 0;
    font-size: 14px;
    font-weight: 500;
}

.small-text {
    font-size: 13px;
}

.location-panel img {
    width: 100%;
}

.location-panel .form-group {
    text-align: right;
}

.location-panel label {
    display: inline-block;
    margin-right: 10px;
}

.location-panel-text:after {
    background-image: url('resources/images/blue-arrow.svg');
    background-repeat: no-repeat;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: "";
    margin-left: 10px;
    position: relative;
    top: 2px;
}

.location-panel .form-control {
    display: inline-block;
    max-width: 70%;
    border: 1px solid #cccccc;
}

.view-listing-link {
    padding-right: 8px;
}

.location-image-upload {
    background-color: #fff;
    padding: 35px 8px;
    border: solid 1px #cccccc;
    border-top: none;
    border-bottom: none;
}

.location-header-image {
    cursor: pointer;
    max-width: 110px;
    max-height: 110px;
}

.location-header-image img {
    cursor: pointer;

}

.image-upload-box {
    display: inline-block;
    background: #f1f1f1;
    width: 200px;
    height: 150px;
    padding: 20px;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.image-upload-box img {
    width: 40%;
    padding-bottom: 10px;
}

.campsite-title {
    font-size: 18px;
    font-weight: 900;
    color: #555666;
}

.campsite-instructions {
    margin-top: 40px;
}

.campsite-instructions .form-control {
    max-width: 100%;
}

.campsite-instructions .alert {
    position: relative;
    top: 27px;
}

.campsite-dividing-line {
    margin-right: -9px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.campsite-dividing-line .dividing-line {
    border-bottom: 1px solid #cccccc;
}

.text-edit .input-group {
    display: inline-block;
    margin-right: 20px;
    width: 80%;
    margin-bottom: 0;
}

.text-edit label {
    display: none;
}

.text-edit a {
    display: inline-block;
    position: relative;
    bottom: 20px;
}

.text-edit-not-editing p {
    display: inline-block;
    margin-right: 20px;
}

.text-edit-not-editing .label {
    display: inline;
}

.campsite-features {
    padding-top: 20px;
}

/*********** LOCATIONS *********/
.locations-panel-details {
    padding-left: 6px;
    cursor: pointer;
}

.locations-panel-details img {
    padding-left: 6px;
    cursor: pointer;
    width: 70px;
    height: 63px;
}

.locations-booked-label {
    text-align: right;
}

/*********** NAV *********/

.navbar .logo {
    width: 159px;
}

.navbar-brand {
    padding-left: 0;
    padding-top: 22px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

a.navbar-link-to-map {
    color: #2196F3 !important;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    padding-left: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

a.navbar-link-to-map:hover {
    text-decoration: none;
}

#homepage a.navbar-link-to-map {
    color: #FFF !important;
}

@media (max-width: 600px) {
    .navbar-link-to-map {
        display: none;
    }
}

.navbar-default .navbar-nav > li > a {
    color: #2196F3;
    font-size: 14px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.navbar .nav {
    margin: 10px 0;
}

.navbar a {
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.navbar-default {
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #ffffff;
    border: none;
    border-radius: 0;
}

.navbar-default .navbar-nav > li > a {
    margin-right: 5px;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #2196F3;
    /*background-color: #f2f2f2;*/
    border-radius: 5px;
    outline: none;
}

/*.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover*/

#homepage .navbar-default .navbar-nav > li > a:hover {
    color: #2196f3;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
    color: #2196F3;
    background-color: #f2f2f2;
    background-image: none;
    box-shadow: none;
    border: 1px solid #e1e1e1;
    border-radius: 5px;

}

.navbar-nav btn {
    float: left;
    position: relative;
    top: 6px;
    font-size: 15px;
    padding: 5px 15px;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
    color: #fff;
    background-color: #5fce17;
    border-color: #5fce17;
}

.dropdown .dropdown-menu li {
    outline: none;
}

.dropdown .dropdown-menu li a {
    outline: none;
}

.add-new-rule-dialog .DateRangePicker {
    width: 100%;
    display: flex;
    justify-content: center;
}

.add-new-rule-dialog .DateRangePicker .DateRangePickerInput {
    border-radius: 8px;
    padding: 4px;
}

.secondary-nav-bar {
    background-color: #004d40;
    margin-bottom: 0;
}

.secondary-nav-bar .container {
    padding-left: 0
}

.secondary-nav-bar .navbar-nav {
    margin: 0 0;
}

.secondary-nav-bar .navbar-nav > li > a {
    color: #fff;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}

.secondary-nav-bar .navbar-text {
    color: #5fce17;
    font-weight: 500;
    margin-left: 0;
}

.secondary-nav-bar .navbar-nav > li > a:hover {
    color: #fff;
    background-color: transparent;
    border-radius: 0;
}

.secondary-nav-bar .navbar-nav > .active > a, .secondary-nav-bar .navbar-nav > .active > a:focus, .secondary-nav-bar .navbar-nav > .active > a:hover, .secondary-nav-bar .navbar-default .navbar-nav > .active > a, .secondary-nav-bar .navbar-default .navbar-nav > .open > a {
    color: #ffffff !important;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 2px #5fce17 solid;

}

.secondary-nav-bar .navbar-nav > li > a:hover {
    color: #fff;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/***********FORMS *********/

.input-group {
    margin-bottom: 20px;
}

.form-control {
    display: block;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-radius: 5px;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
}

.form-control:hover {
    border-color: #5fce17;
    cursor: pointer;
}

.form-control:focus {
    cursor: text;
    border-color: #cccccc;
    border: #cccccc 1px solid;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 0 0 5px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 1px 1px 5px rgba(0, 0, 0, 0);
    border-radius: 5px;
}

.cleave {
    width: 100%;
    display: block;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-radius: 5px;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
}

.cleave:hover {
    border-color: #5fce17;
    cursor: pointer;
}

.cleave:focus {
    cursor: text;
    border-color: #cccccc;
    border: #cccccc 1px solid;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 0 0 5px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 1px 1px 5px rgba(0, 0, 0, 0);
    border-radius: 5px;
}

.cleave input::-webkit-input-placeholder {
    color: #888888;
}

.cleave input::-moz-placeholder {
    color: #888888;
}

.cleave input:-ms-input-placeholder {
    color: #888888;
}

.cleave input:-moz-placeholder {
    color: #888888;
}

.onboarding .form-control {

}

.onboarding .input-group, .login-box .input-group {
    overflow: hidden;
    display: block;
}

.form-alert-help {
    display: inline-block;
    max-width: 33%;
    margin-left: 15px;
}

.alert-danger {
    background-image: none;
    border: none;
    background-color: #FFE6E6;
}

.input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 5px;
}

.alert {
    margin-bottom: 0;
}

.alert-danger {
    color: #888888;
    font-weight: 500;
}

.form-before-editing {
    font-size: 125%;
    border-bottom: #cccccc solid 1px;
    margin-bottom: 17px;
    padding-bottom: 7px;
    display: inline-block;
    width: 74%;
}

.account-profile label, .account-payment label {
    display: inline-block;
    margin-right: 10px;
    width: 20%;
}

.account-profile .form-group {
    display: inline-block;
    width: 89%;
}

.account-profile .form-control {
    width: 74%;
    display: inline-block;
}

.account-profile .form-group {
    display: inline-block;
    width: 89%;
}

.account-profile .react-tel-input {
    width: 74%;
    display: inline-block;
}

.account-profile a {
    display: inline-block;
}

.account-identity-error {
    color: red;
}

.account-identity h5 {
    padding-top: 15px;
}

.account-identity-types {
padding: 10px 0;
}

.identity-type-wrapper {
    display: inline-block;
    padding-right: 20px;
}

.image-input-identity {
    background-color: #F2F2F2;
    border: 1px solid #888888;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
    max-width: 550px;
}

.image-input-identity > img {
    display: block;
    margin: 0 auto;
    max-height: 200px;
    width: auto;
}

.image-input-identity > label {
    margin: 10px 0;
}

.checkbox input[type=checkbox] {
    border-radius: 30px;
    -webkit-appearance: button;
    border: solid 1px #cccccc;
    white-space: nowrap;
    overflow: hidden;
    left: -25px;
    top: -14px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

input[type=checkbox]:checked {
    background: url('resources/images/green-tick.svg');
}

input[type="checkbox"]:focus {
    outline: 0;
}

.checkbox input[type=checkbox] {
    margin-left: 0px;
}

.checkbox input[type=checkbox] {
    background-repeat: no-repeat;
}

.checkbox {
    margin: 0 0 30px 25px;
    width: 28%;
    display: inline-block;
}

.location-detail-view .checkbox-group {
    width: 100%;
    margin-top: 20px;
}

.form-disabled-field {
    display: inline-block;
    font-size: 16px;
    color: #555;
    padding-left: 5px;
    width: 78%;
}

.location-container label {
    font-weight: 500;
}

/* end .checkbox-rounded */

/*********** DIVIDING HEADER **********/

.dividing-header {
    position: relative;
    top: -1px;
}

.dividing-header h3 {
    font-size: 16px;
    color: #888888;
    background: #f1f1f1;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    border: 1px solid #cccccc;
    padding: 10px 35px;
    border-radius: 40px;
    width: 380px;
    margin: auto;
    text-align: center;
    font-weight: 500;
}

.dividing-header .white-top {
    background: #fff;
    border: #cccccc solid 1px;
    border-top: none;
    height: 25px;
}

.dividing-header .grey-top {
    background: #f2f2f2;
    border: #cccccc solid 1px;
    border-top: none;
    height: 25px;
}

.dividing-header .grey-bottom {
    background: #f1f1f1;
    border: #cccccc solid 1px;
    border-top: none;
    border-bottom: none;
    height: 25px;
}

.create-new-campsite h3 {
    top: -20px;
}

.create-new-campsite {
    top: 60px;
    margin-bottom: 70px;
    text-align: center;
}

.create-new-campsite img {
    width: 140px;
}

.create-new-campsite .lower-block {
    padding: 0 15% 50px;

}

/*********** PROGRESS INFORMATION ***********/

/* #progress-information {
    padding: 15px;
    background: #fff;
    border: #cccccc solid 1px;
    border-top:none;
    border-bottom:none;
 }
 #progress-information h4 {
 }*/
.progress-information {
    margin-bottom: 15px;
    display: inline-block;
}

.input-group-addon {
    visibility: hidden;
    background-color: transparent;
    color: #337ab7;
    border: none;
}

.green-well {
    background: #e6fde2;
    padding: 12px 25px 14px;
    border-radius: 5px;
    /*   border: 1px solid #9DE56E;*/
}

.red-well {
    background: #FFE5EA;
    padding: 12px 25px 14px;
    border-radius: 5px;
    border: 1px solid #FFBCC9;
}

/************ ONBOARDING *************/

.form-control:hover + .input-group-addon {
    visibility: visible;
}

.you-have-no-properties {
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 33px 35px;
}

.you-have-no-properties .mint-bottom-box {
    margin: 40px -35px -33px;
}

.you-have-no-properties-menu {
    padding: 20px;
}

.img-circle {
    height: 180px;
    width: 180px;
}

.file-input-box {
    width: 180px;
    margin: -30px auto 20px;
}

.file {
    position: relative;
    height: 30px;
    width: 180px;
    left: 32px;
    margin-top: 10px;
}

.file > input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.file > label {
    position: absolute;
    background-color: transparent;
    color: #0482E6;
    line-height: 30px;
    width: 180.266px
}

#hostCard {
    padding-right: 0px;
}

img.image-input-image-circle.img-circle {
    display: block;
    margin: auto;
}

/***************** GALLERY ****************/

.sortable-image-gallery {
    margin-bottom: 40px;
}

.image-holder {
    cursor: move;
    width: 24%;
    padding: 0 10px 20px;
    display: inline-block;
    position: relative;
    height: 24%;
}

.red-x {
    position: absolute;
    top: -9px;
    right: -3px;
    height: 20px;
    z-index: 100;
    width: 40px;
}

/************** DROPZONE ******************/

.dropzone {
    border: 2px dashed #cccccc;
    padding: 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}

@media all and (max-width: 699px) {
    .navbar-header {
        height: 65px;
    }

    .navbar-brand {
        padding-left: 5px;
        padding-top: 22px;
    }

    .navbar-default .navbar-toggle {
        margin-top: 20px;
        margin-right: 25px;
    }
}

/************* HOST HOMEPAGE CTA ************/

.host-homepage-cta {
    margin: 0;
    padding: 45px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.host-homepage-cta .description {
    text-align: center;
}

.host-homepage-cta .subtitle {
    text-align: center;
}

.host-homepage-cta .list-block h4 {
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #555555;
    display: inline-block;
    width: 160px;
    margin-left: 10px;
    margin-top: 4px;
}

.host-homepage-cta .list-block img {
    width: 31px;
    height: 31px;
}

.host-homepage-cta .frosted-box {
    max-width: 800px;
    margin: 0 auto;
    cursor: pointer;
}

.frosted-box:hover {
    opacity: 1;
}

.account-payment .cardDetail {
    margin: 10px auto;
}

.account-payment .edit-credit-card-container {
    margin: 10px auto;
}

.onboarding .nextButtonContainer {
    text-align: right;
}

.onboarding .nextButtonContainer .skip {
    padding: 0 15px 0 15px;
}

.onboardingHostCampContainer {
    text-align: center;
}

.onboarding .onboardingHostCampContainer .button {
    margin: 10px auto 10px auto;
}

.onboarding .letsGoCampingContainer {
    text-align: center;
}

.onboarding .letsGoCampingContainer .button {
    margin: 10px auto 10px auto;
    width: 150px;
}

.hostOnboarding .nextButtonContainer {
    text-align: right;
}

.hostOnboarding .nextButtonContainer .skip {
    padding: 0 15px 0 15px;
}

.hostOnboarding .onboardingHostCampContainer {
    text-align: center;
}

.hostOnboarding .onboardingHostCampContainer .button {
    margin: 10px auto 10px auto;
}

.hostOnboarding .letsGoCampingContainer {
    text-align: center;
}

.hostOnboarding .letsGoCampingContainer .button {
    margin: 10px auto 10px auto;
    width: 150px;
}

/********* CONTACT FORM ******/

.contact-container .well {
    margin-top: 10px;
    color: #555;
    font-weight: 500;
}

/*********** FOOTER *********/

.footer {
    padding: 0 0 50px;
}

.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer li {

}

.footer h4 {
    font-size: 15px;
    margin-bottom: 12px;
    margin-top: 0;
}

.footer a {
    text-align: center;
    color: #888888;
    font-weight: 300;
    font-size: 14px;
    -webkit-font-smoothing: initial;
}

.footer-column-one button {
    margin-bottom: 10px;
}

.footer-column-one li {
    margin-bottom: 10px;
}

.footer-column-one a {
    color: #44A8F7;
    font-weight: 500;
}

.footer-contact-us-box {
    border: 1px solid #e1e1e1;
    padding: 10px 13px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    line-height: 2rem;
    margin-top: 25px;
}

.footer-contact-us-box h3 {
    font-size: 15px;
    margin-top: 3px;
    font-weight: 500;
}

.support-logo-container{
}
.support-logos{
    text-align: center;
}
.support-logos img{
    margin: 0px 9px;
    height: 70px;
    width: auto;
}
.support-logos a:hover{
    color: transparent;
}

.login-box .social-box {
    text-align: center;
}

.login-box .social-box {
    text-align: center;
}

.login-box .login-button-box {
    text-align: center;
}

.login-box .signup-box {
    text-align: center;
}

/********* DIVIDER ********/

.divider {
    height: 1px;
    background: #cccccc;
    margin: 0 -1000px;
}

/********* MEDIA QUERIES-HOMEPAGE ********/

.collection-banner {
    margin: -50px -50px;
    background-position: center;
}

@media (max-width: 767px) {

    .page-content {
        padding-left: 0;
        padding-right: 0;
    }

    .collection-block .collection-tile {
        padding-left: 0px;
        padding-right: 0px;
    }

    h1 {
        font-size: xx-large !important;
    }

    .home-banner input {
        height: 70px;
        font-size: 28px;
    }

    .home-banner {
        padding: 80px 0px;
        background-position: center;
    }

    .home-banner h1 {
        font-size: 46px !important;
    }

    .home-banner h1 strong {
        font-size: 40px;
        line-height: 74px;
    }

    /*#homepage .list-block.col-md-3, #homepage .list-block.col-md-4 {*/
    /*padding: 15px 0px;*/
    /*}*/
    .location-container .nav > li > a {
        padding: 10px 5px;
    }

    .host-homepage-cta {
        padding: 20px;
        margin: 0 -30px;
    }

    .nav-pills {
        margin-bottom: 30px;
    }

    #homepage h2, .list-block .description, .host-homepage-cta .description, .host-homepage-cta .subtitle {
        text-align: left;

    }

    .host-homepage-cta .list-block h4 {
        font-size: 18px !important;
        width: 75%;
    }

    .call-to-action-banner {
        margin: 0px -30px;
    }

    .call-to-action-banner .frosted-box.row {
        padding: 10px 15px 10px;
    }

    .call-to-action-banner.container {
        width: 100%;
    }

    .call-to-action-banner-buttons .btn {
        margin: 15px auto;
    }

    #homepage .list-block-button {
        text-align: left;
    }

    .call-to-action-banner-buttons p {
        display: none;
    }

    .footer h4 {
        font-size: 20px;
        text-align: center;
    }

    .footer-column-one button {
        font-size: 22px;
        text-align: center;
        display: block;
        margin: 10px auto !important;

    }

    .footer-column-one a, .footer ul {
        text-align: center;
        font-size: 16px;
    }

    .host-banner {
        margin: -40px -45px 0;
        padding: 20px 0 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .locations-map .location-tile-image img {
        width: auto;
        height: 100%;
    }

    .list-block {
        margin-bottom: 23px;
        margin-left: 10px;
    }

    .frosted-box {
        padding: 10px 18px 0px;
    }

    .table-responsive {
        border: none;
    }

    .host-transactions-container .card {
        padding: 12px 0 !important;
        margin: 0 -15px;
    }

    .host-bookings-panel .booking-cancel-row {
        text-align: left;
    }

    .location-panels {
        margin: 0 -15px;
    }

    .location-header {
        padding: 0 10px 9px 5px;
    }

    .location-header-image {
        max-height: 160px;
        overflow: hidden;
    }

    .locations-panel-details {
        padding-top: 11px;
    }

    .location-panel .form-group {
        text-align: left;
        padding-top: 20px;
        margin-bottom: 0;
    }

    .location-panel .form-control {
        max-width: 80%;
    }

    .location-detail-view {
        margin: 0 -15px;
    }

    .location-header img {
        width: 35px;
    }

    .location-detail-view label {
        width: 100%;
        text-align: left;
        padding-right: 10px;
    }

    .location-detail-view input {
        width: 100%;
    }

    .inline-card .location-address div:first-of-type {
        width: 100% !important;
    }

    .location-detail-view textarea {
        width: 100%;
    }

    .location-detail-view .inline-card {
        padding: 40px 15px 40px;
    }

    .location-map {
        height: 15vh !important;
    }

    .image-holder {
        width: 48%;
    }

    .campsite-header {
        padding: 3px 10px 5px 10px;
    }

    .campsite-header img {
        width: 35px;
    }

    .link-property-vs-campsite {
        margin-left: 0;
        display: block;
        margin-bottom: 10px;
    }

    .location-booking-information .booking-info-white-area {
        padding: 20px 10px 10px;
        border-bottom: 1px solid #e1e1e1;
        border-radius: 5px 5px 5px 5px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .collection-block .tile {
        width: 47%;
        margin: 6px;
        margin-bottom: 0px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .collection-block.container {
        width: 100%;
    }

    /*.collection-block .tile{*/
    /*width:50%;*/
    /*}*/
    .list-block.container {
        width: 100%;
    }

    #homepage .list-block.col-md-3 {
        display: inline-block;
        width: 50%;
        padding-top: 15px;
    }

    #homepage .list-block-button {
        text-align: left;
    }

    .host-banner {
        margin: -40px -245px 0;
        padding: 20px 0 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .home-banner input {
        height: 55px;
        font-size: 22px;
    }

    .host-homepage-cta.container {
        width: 100%;
    }

    .call-to-action-banner-buttons.col-md-6 p {
        text-align: center;
    }

    .call-to-action-banner-buttons .btn {
        margin: 15px auto;
    }

    .call-to-action-banner-buttons.col-md-6 {
        display: inline-block;
        width: 50%;
    }

    /*.cta-contents {*/
    /*display: inline-block;*/
    /*width: 50%;*/
    /*}*/
    .frosted-box {
        display: none;
    }

    .list-block.col-md-4 {
        display: block;
    }

    #homepage {
        overflow-x: hidden;
    }

    .locations-map .location-tile-image img {
        width: auto;
        height: 100%;
    }

}

/********* MEDIA QUERIES-MAP PAGE ********/
@media (max-width: 767px) {

    .locations-location-tile {
        padding: 0px 15px 15px 15px;
    }

    .google-map {
        padding: 0px 15px 15px 15px;
        margin-bottom: 10px;
        height: 60vh;
    }
}

/********* MEDIA QUERIES-STANDARD PAGE ********/

@media (max-width: 767px) {

    .standard-page .list-block.col-md-4 {
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .call-to-action-banner-buttons .btn {
        font-size: 16px;
    }

    .standard-page h1 {
        margin: 0px 0 7px;
    }

    .contentful-column {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .contentful-row {
        margin: 0px 0px 45px;
    }

}

@media (min-width: 768px) and (max-width: 992px) {

    .standard-page .list-block.col-md-4 {
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;

    }

}

/********* MEDIA QUERIES-BECOME HOST PAGE ********/

@media (max-width: 767px) {

    #hostCard {
        padding-left: 0px;
        padding-right: 0px;
    }

    #cardButton {
        margin: auto;
        display: block;
    }

    .checkbox {
        width: 80%;
        padding: 5px;

    }

}

@media (min-width: 768px) and (max-width: 992px) {

    .checkbox {
        width: 29%;
    }

    #hostCard {
        padding-left: 0px;
        padding-right: 0px;
    }

}

@media (min-width: 700px) and (max-width: 767px) {
    .navbar-brand {
        padding-left: 35px;
        padding-top: 18px;
    }
}

/********* MEDIA QUERIES-MOBILE NAV ********/

@media (max-width: 767px) {

    .navbar-default {
        border-color: none;
        border: none;
    }

    #homepage .navbar-collapse.collapse.in {
        background-color: #ffffff;
        border: 0px;
        width: 100%;
        margin-left: 0px !important;
        padding-left: 0px;

    }

    #homepage .navbar-collapse {
        border: none !important;
        border-bottom-color: none !important;
        border-image-width: none;
    }

    #homepage .navbar-default .navbar-collapse, #homepage .navbar-default .navbar-form {
        border-bottom-color: none !important;
    }

    #homepage .navbar-default .navbar-nav > li > a {
        color: #414042;
    }

    .secondary-nav-bar {
        display: none;
    }

    #homepage .navbar-brand {
        height: 65px;
        padding-left: 30px;
    }

    #homepage .navbar .container {
        padding-left: 0px !important;
    }

    /*.card {*/
    /*padding: 15px 10px 15px !important;*/
    /*}*/
    /*.container {*/
    /*padding-right: 5px;*/
    /*padding-left: 5px;*/
    /*}*/

}

@media (min-width: 767px) {

    .second-mobile-nav {
        display: none !important;
    }

}

.loaderContainer {
    height: 120vmax;
}

/*** SLICK SLIDER ****/

/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 15px;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    margin-right: 10px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
    width: auto;
    height: 358px;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: url('resources/images/green-arrow-back.svg');
}

[dir='rtl'] .slick-prev:before {
    content: url('resources/images/green-arrow-back.svg');
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: url('resources/images/green-arrow-forward.svg');
}

[dir='rtl'] .slick-next:before {
    content: url('resources/images/green-arrow-forward.svg');
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {

    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: url('resources/images/green-arrow-back.svg');
}

[dir='rtl'] .slick-prev:before {
    content: url('resources/images/green-arrow-back.svg');
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: url('resources/images/green-arrow-forward.svg');
}

[dir='rtl'] .slick-next:before {
    content: url('resources/images/green-arrow-forward.svg');
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {

    font-size: 35px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

@media (max-width: 767px) {
    .slick-slider {
        margin-bottom: 5px;
    }

    .slick-prev, .slick-next {
        display: none !important;
    }

    .slick-dots {
        margin: 15px 0;
        bottom: 0;
    }

    .slick-dots li button:before {
        color: white;
    }

    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: white;
    }
}

/* -- Custom Dialog styling, must use modal naming to inherit styles set but React bootstrap -- */

.modal {
    z-index: 99999;
}

.modal .modal-header {

}

.modal .modal-header .modal-title {

}

.modal .modal-body {
    padding: 35px 15px 0 25px;
}

.modal .modal-footer {
    height: auto;
}

.modal .modal-footer .positiveButton {

}

.modal .modal-footer .negativeButton {
    color: #2196F3;
    font-weight: 500;
    cursor: pointer;
    padding: 15px;
}

.selected-booking-tile-container {
    display: inline-block;
    margin-left: 15px;
    padding: 5px 10px;
    border: 1px solid #5fce17;
    text-align: center;
    border-radius: 5px;
}

.selected-booking-tile-container .arrival-departure-container {
    display: inline-block;
    text-align: center;
}

.selected-booking-tile-container .arrival-departure-container p {
    color: #555;
    margin-bottom: 0;
}

.selected-booking-tile-container h4 {
    margin-top: 0;
    color: #5fce17;
}

.location-booking-information .update-detail-panel {
    max-height: 45%;
}

.location-booking-information .update-detail-panel .update-details-button-group .button-container {
    text-align: center;
}

.location-booking-calendar-container.location-booking-calendar-header-container {

}

.location-booking-calendar-container .location-booking-calendar-header-container h3 {
    display: inline-block;
    width: 14.2857142857%;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    margin: 0 0 0 0;
    user-select: none;
}

.day-container {
    user-select: none;
}

/* -- No day --*/
.location-booking-calendar-container .no-day-container {
    display: inline-block;
    width: 13.78%;
    margin: 0.25%;
}

/* -- Day container --*/

.location-booking-calendar-container .day-container {
    display: inline-block;
    width: 13.78%;
    text-align: center;
    padding: 8px 5px 11px 5px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin: 0.25%;

}

.location-booking-calendar-container .day-container .button {
    width: 90%;
    margin: auto;
    align-items: center;
    padding: 6px 0;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.booking-calendar-link {
    padding-top: 26px;
    display: block;
    text-align: right;
    padding-right: 20px;
}

/*.booking-calendar-link: before {*/
/*background-image: url('resources/images/calendar.png');*/
/*background-repeat: no-repeat;*/
/*display: inline-block;*/
/*width: 20px;*/
/*height: 18px;*/
/*content: "";*/
/*margin-left: 0px;*/
/*position: relative;*/
/*top: 6px;*/
/*margin-right: 7px;*/
/*opacity: 0.8;*/
/*margin-bottom: 3px;*/
/*background-size: 20px;*/
/*}*/

.location-booking-calendar-container .day-container h5 {
    font-size: 16px;
    color: #5fce17;
    margin-top: 0;
}

/* -- Day available -- */
.location-booking-calendar-container .available {
}

.location-booking-calendar-container .available .button {

}

/* -- Day unavailable -- */

.location-booking-calendar-container .unavailable {
    border-color: #e1e1e1;
    background-color: #f2f2f2;
}

.location-booking-calendar-container .unavailable h5 {
    font-size: 16px;
    color: #f2f2f2;
    user-select: none;
}

.location-booking-calendar-container .unavailable .button {
    color: #b7b7b7;
    border: 1px solid #b7b7b7;
    background: #f2f2f2;
    padding-top: 8px;
    padding-bottom: 8px;
}

/* -- Day unavailable booked -- */

.location-booking-calendar-container .unavailable-booked {
    border-color: #e1e1e1;
    background-color: #f2f2f2;
}

.location-booking-calendar-container .unavailable-booked h5 {
    font-size: 16px;
    color: #b7b7b7;
}

.location-booking-calendar-container .unavailable-booked .button {
    color: #b7b7b7;
    border-color: #b7b7b7;
    padding-top: 8px;
    padding-bottom: 8px;
}

/* -- Day gone -- */

.location-booking-calendar-container .gone {
    border-color: #e1e1e1;
    background-color: #f2f2f2;
    color: #b7b7b7;
}

.location-booking-calendar-container .gone h5 {
    font-size: 16px;
    color: #f2f2f2;
}

.location-booking-calendar-container .gone .button {
    color: #b7b7b7;
    border: 1px solid #b7b7b7;
    background: #f2f2f2;
    padding-top: 8px;
    padding-bottom: 8px;
}

/* -- Day being booked -- */

.location-booking-calendar-container .booking {
    background-color: #fff;
    border-color: #5fce17;
}

.location-booking-calendar-container .booking .button {
    background-color: #fff;
    color: #5fce17;
    border: 1px solid #5fce17;
    padding-top: 8px;
    padding-bottom: 8px;
}

/* -- Day booked -- */

.location-booking-calendar-container .booked {
    background-color: #fcfffa;
    border-color: #5fce17;
}

.location-booking-calendar-container .booked h5 {
    font-size: 16px;
    color: #5fce17;
}

.location-booking-calendar-container .booked .button {
    background-color: #fcfffa;
    color: #5fce17;
    border: 3px solid #5fce17;
}

/*.location-booking-calendar-container .scroll-container {*/
/*width: 100%;*/
/*height: 600px;*/
/*}*/

@media (max-width: 960px) {
    .day-tiles {
        height: 116px;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .location-booking-calendar-container .day-container {
        width: 122px;
        margin: 0;
        margin-right: 7px;
    }

    .location-booking-information .booking-info-white-area img {
        display: none;
    }

    .location-booking-calendar-header-container {
        display: none;
    }

    .location-booking-information .booking-info-white-area h4 {
        margin-top: 0;
    }

    .location-booking-information .booking-info-white-area {
        padding: 20px 10px 10px;
        border-bottom: 1px solid #e1e1e1;
        border-radius: 5px 5px 5px 5px;
    }

    .booking-info-list-of-features, .location-booking-information .booking-info-white-area p {
        margin-bottom: 10px;
    }

    .booking-calendar-link {
        padding-top: 0px;
    }
}

.bookings-row-container {
    border: 1px solid #e1e1e1;
    padding: 14px 23px;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 5px;
}

.bookings-row-container h2 {
    color: #2196F3;
}

.bookings-row-container a {
    font-size: 28px;
}

.bookings-row-container h3 {
}

.required-text {
    color: #555 !important;
    display: inline-block;
}

.required-text .star {
    color: red;
    display: inline-block;
}

.form-group label .required-star {
    color: red !important;
    font-size: 12px;
}

.onboarding-loading {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 20px;
    background-color: #e1e1e1;
    z-index: 50;
    opacity: 0.55;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
}

.dialog-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e1e1e1;
    z-index: 50;
    opacity: 0.55;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
}

.onboarding-current-info {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
}

.onboarding-current-info h4 {
    color: #d9000c;
    margin: 15px;
}

.onboarding-current-info button {

}

.login-overlay {

}

.login-overlay .modal-dialog {
}

.login-overlay .modal-content {
    background: none;
    box-shadow: none;
    border: none;
    outline: none;

}

.overflow-hidden {
    overflow: hidden;
    display: block;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    display: block;
    /*background-color: #e1e1e1;*/
    background-color: #e1e1e1;
    background-size: cover;
    color: #fff;
    -webkit-filter: blur(20px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
    /*opacity: 0.95;*/
    z-index: 8888;
}

.login-overlay h4 {
    display: inline-block;
}

.login-overlay .close-btn {
    position: relative;
    top: -14px;
    right: -12px;
    /*background-color: #666666;*/
}

.login-overlay .close-btn a {
    position: absolute;
    top: 0;
    font-size: 1em;
    cursor: pointer;
    text-underline: none;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
}

.login-overlay .close-btn a:hover {
    /*text-decoration: none !important;*/
}

.inner-container-hidden {
    display: none;
}

.inner-container-hidden .standard-page {
    display: none;
}

.calendar-container {
    position: absolute;
    top: 0;
    left: -350px;
}

.app-download-badge {
    width: 155px;
    height: 60px;
    display: inline-block;
}

.rule-item {

}

.rule-item h5 {
    display: flex;
}

.rule-item img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.availability-control-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
}

.availability-control-block .btn-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.campsite-availability-rules table {
    width: 100%;
}

/*.campsite-availability-rules table tr th{*/
/*width: 100%;*/
/*}*/

/*.campsite-availability-rules table tr th{*/
/*width: 100%;*/
/*}*/

/*.campsite-availability-rules table tr th h5{*/
/*width: 100%;*/
/*}*/

.add-new-rule-dialog {

}

.add-new-rule-dialog .checkbox-group {
    display: inline-block;
    vertical-align: top;
    padding: 15px;
    margin-bottom: -40px;
    width: 95%;
}

/*.tile h4{*/
/*font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;*/
/*font-weight: 300;*/
/*font-size:16px;*/
/*color:#2196f3;*/
/*text-align:left;*/
/*word-break: break-word;*/
/*}*/

/*.tile{*/
/*background-color:#ffffff;*/
/*border:1px solid rgba(148, 151, 153, 0.31);*/
/*border-radius: 5px;*/
/*padding-bottom: 12px;*/
/*min-height: 250px;*/
/*width: 225px;*/
/*margin-right: 15px;*/
/*padding-right: 0px;*/
/*padding-left: 0px;*/

/*}*/

.tile-info {
    padding-left: 9px;
    padding-right: 10px;

}

.tile-info h4 {
    margin-bottom: 0;
    margin-top: 14px;
}

.tile-info a {
    text-decoration: none !important;
}

.ratings {
    position: absolute;
    bottom: 0px;
    padding-left: 12px;
    padding-bottom: 10px;
}

.location-flag p {
    pointer-events: none;
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none !important;
    color: #b7b7b7;
}

.tile a {
    text-decoration: none !important;
}

.location-flag img {
    display: inline-block;
    width: 12px;
    margin-right: 5px;
}

.location-flag p {
    display: inline-block;
}

body {
    font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 280px;
    height: 50px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    width: 100%;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 50px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

/*///  NEW LISTING ITEMS /////*/
.listing-item {
}

/* //// ADMIN //// */
.admin-block-button {
    text-align: center;
}

.admin-settings-panel a {
    padding-right: 10px;
}

.admin-settings-panel {
    padding-top: 10px;
}

p.admin-block-button {
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
}

.admin-block {
    display: inline-block;
}

.admin-checkbox {
    margin-top: 10px;
}
