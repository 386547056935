body {
    margin: 0;
    padding: 0;
    font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 15px;
    background: #FFFFFF;
    background-size: 75%;
    color: #888888;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
    color: #555;
}

h2, h3, h4 {
    font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

h1 {
    margin: 36px 0 32px;
    font-size: 32px;
    font-weight: 900;
}

h2 {
    /*margin: 40px 0 20px;*/
}

.contentful-column h3 {
    margin-bottom: 21px;
}

.contentful-column p {
    color: #555;
}

.contentful-column li {
    color: #555;
    font-weight: 300;
    line-height: 3rem;
    -webkit-font-smoothing: initial;
}

h4 {
    font-size: 18px;
    font-weight: 500;
}

p {
    -webkit-font-smoothing: initial;
}

a {
    color: #2196F3;
    font-weight: 500;
    cursor: pointer;
}

a:focus, a:hover {
    color: #2196F3;
}

.blue-arrow:after {
    content: url('resources/images/blue-arrow-link.svg');
    margin: 0 0 0 7px;
    text-decoration: none;
    display: inline-block;
    width: 7px;
}

li {
    color: #555555;
    font-weight: 500;
    line-height: 2.3rem;
}

hr {
    border-top: 1px solid #cccccc;
    margin: 35px -15px;
}

.table {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 9px;
}

.table > thead > tr > th {
    vertical-align: bottom;
    font-size: 12px;
    font-weight: 500;
    color: #666;
    padding-bottom: 0;
    border-bottom: none;
}

.table > tbody > tr > td {
    border-top: none;
    padding-top: 2px;
    padding-bottom: 19px;
}

label {
    font-size: 13px;
    font-weight: 900;
    display: block;
    margin-bottom: 5px;
    color: #666;
}

img {
    width: 100%;
}

strong {
    font-weight: 500;
}

ul {
    padding-left: 25px;
}

ul .tick-li {
    list-style: url('resources/images/green-tick.svg');
}

.description {
    font-size: 18px;
    margin: 15px auto 40px;
    max-width: 500px;
}
h1.page-title {
    padding-left: 13px;
}

.card {
    background: #fff;
    margin: 0 0 20px;
}
.card h1 {
    padding-left: 13px;
    margin: 7px 0 15px;
}

@media (max-width: 600px) {
    .card {
        padding: 0 20px;
    }
    h1.page-title {
        padding-left: 0;
    }
}

.navbar .container {
    width: 100%;
    padding: 0 0 0 35px;
}

.inline-card {
    background-color: #fff;
    padding: 40px 30px 40px;
    border: solid 1px #cccccc;
    border-top: none;
}

.inline-card .location-address div:first-of-type {
    display: inline-block;
    width: 80%;
}

.inline-card .location-address div:first-of-type input {
    display: inline-block;
    width: 100%;
}

.onboarding-card {
    padding: 0
}

.onboarding-card .card {
    padding: 0px 0px;
    background: transparent;
}

.onboarding-card .help-text {
    padding: 20px;
}

.onboarding-profile-image img {
    width: 50%;
}

.green-button-box {
    background: #e6fde2;
    border-top: #5fce17 solid 1px;
    padding: 10px 20px;
}

.green-button-box .btn {
    margin-right: 15px;
    padding: 5px 30px;
}

/********** FORMS *************/

.form-validation-tick {
    background: url('resources/images/green-tick.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 98%;
}

.form-control {
    -webkit-font-smoothing: initial;
}

.form-control label .required-star {
    color: red!important;
}

/********* LISTING IAMGES ***********/

.image-gallery-content {
    max-height: 800px;
    overflow: hidden;
}

.image-gallery-slide-wrapper {
    max-height: 400px;
}

.image-gallery-slide {
    background: #f2f2f2;
}

/********* HOST IMAGES / GALLERIES  **********/

.image-input label {
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {

    color: white;
    background-color: #5fce17;
    display: inline-block;
    padding: 6px 22px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 900;
    line-height: 1.42857143;
    border: 3px solid #9de56e;
    border-radius: 50px;
    text-align: center;
    cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #5fce17;
    border-color: #5fce17;
    color: #ffffff;
}

.inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    pointer-events: none;
}

.image-gallery ul {
    list-style: none;
}

.image-gallery li {
    list-style: none;
    width: 200px;
    height: 100px;
    display: flex;
}

.dropdown-menu {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    background: #fff;
    padding: 4px;
}

.dropdown-menu > li > a {
    background: #fff;
    display: block;
    padding: 10px 40px 10px 20px;
    clear: both;
    font-weight: 500;
    line-height: 1.428571;
    color: #2196F3;
    white-space: nowrap;
    margin-bottom: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #fff;
}

.dropdown-menu > li > a:hover {
    background-color: #f2f2f2;
    background-image: none;
    filter: none;
    background-repeat: no-repeat;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: #2196F3;
    text-decoration: none;
    background-color: #f2f2f2;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: #f2f2f2;
    background-image: none;
    -webkit-filter: none;
    filter: none;
    color: #2196F3;
    font-weight: 500;
    border-color: #e1e1e1;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #555;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: #f2f2f2;
    background-image: none;
    filter: none;
    color: #2196F3 !important;
    font-weight: 500 !important;
}

/********** BUTTONS *******/

.btn-danger, .btn-default, .btn-info, .btn-primary, .btn-success, .btn-warning {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-default {
    background-image: none;
    color: #fff;
    background-color: #5fce17;
    border-color: #9de56e;
    transition: 0.5s ease;
}

.btn {
    display: inline-block;
    padding: 6px 22px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 900;
    line-height: 1.42857143;
    border: 3px solid #9de56e;
    border-radius: 50px;
}

.btn-default:focus, .btn-default:hover, .btn-default:target, .btn-default:active,
.btn-default.focus, .btn-default.hover, .btn-default.target, .btn-default.active,
.btn-default:active:hover {
    background-color: #5fce17;
    border-color: #5fce17;
    color: #ffffff;
    outline: none;
}


button {
    outline: none!important;
}


.btn-inverse {
    border: 2px solid #9DE56E;
    color: #5fce17;
    background-color: transparent;
    outline: none!important;
}

.btn-inverse:focus, .btn-inverse:hover, .btn-inverse:target, .btn-inverse:active,
.btn-inverse.focus, .btn-inverse.hover, .btn-inverse.target, .btn-inverse.active,
.btn-inverse:active:hover, .btn-inverse:active:focus, .btn-inverse:active:hover {
    border: 2px solid #5fce17;
    color: #5fce17;
    background-color: #ffffff;
}

.btn-facebook {
    border: 2px solid #0482e6;
    color: #0482e6;
    background-color: #ffffff;
}

.btn-facebook:focus, .btn-facebook:hover, .btn-facebook:target, .btn-facebook:active,
.btn-facebook.focus, .btn-facebook.hover, .btn-facebook.target, .btn-facebook.active,
.btn-facebook:active:hover, .btn-facebook:active:focus, .btn-facebook:active:hover {
    border: 2px solid #44A8F7;
    color: #0482e6;
    background-color: #ffffff;

}

/*#homepage .navbar-default .navbar-nav> .become-a-host-nav-link a {*/
/*background: #5fce17;*/
/*border-radius: 50px;*/
/*border: 2px solid #b8e09d;*/
/*font-weight: 900;*/
/*}*/

.green-tick {
    width: 3%;
    height: auto;
}

.how-do-i-get-paid {
    margin-top: 20px;
}

.update-detail-panel {
    background: #e6fde2;
    border-top: 1px solid #5fce17;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 10px 0;
    z-index: 9999;
}

.update-detail-panel .update-detail-message {
    display: inline-block;
    margin-right: 20px;
    text-align: left;
}

.update-detail-panel h4 {
    margin-bottom: 5px;
}

.update-detail-panel .update-details-button-group {
    position: relative;
    top: 10px;
}

.update-detail-panel .update-details-button-group .btn {
    margin-right: 20px;
}


.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}

.row-centre-vert{
    text-align: center;
    }

.row-centre-vert button{
    vertical-align: middle;
    position: relative;
    top: 10px;
}

.table-deleted {
    color: red;
}