.widget-container {
    border: 1px solid #e0e0e0;
    position: relative;
    overflow: hidden;
}
.widget-strap-line {
    position: relative;
    z-index: 99;
    /*padding: 36px 20px 24px;*/
}
.widget-search-bar {
    position: relative;
    z-index: 100;
    /*
    padding: 0 20px;
    */
}

/*.widget-search-bar .pac-container .pac-logo {
    display: none;
}*/
.widget-search-bar button {
    position: absolute;
    top: 7px;
    right: 32px;
    width: 27px;
    background: transparent;
    border: none;
}

.widget-search-bar img {
    width: 27px;
    height: 27px;
}

.widget-search-bar input {
    padding: 10px 12px;
    font-size: 15px;
    border:none;
    border: 1px solid #9DE56E;
    width: 100%;
    color: #555;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: italic;
    color: #888;
}
::-moz-placeholder { /* Firefox 19+ */
    font-style: italic;color: #888;
}
:-ms-input-placeholder { /* IE 10+ */
    font-style: italic;color: #888;
}
:-moz-placeholder { /* Firefox 18- */
    font-style: italic;color: #888;
}
.widget-strap-line h1, .widget-strap-line h3 {
    color: #fff;
}
@media screen and (min-width: 299px) and (max-width: 301px) and (min-height: 600px) and (max-height: 601px){
    .widget-strap-line h1 {
        font-size: 32px !important;
        margin: 0 0 10px!important;
        padding: 0 0;
    }
    .widget-strap-line h3{
        font-size: 28px;
    }
}


.widget-strap-line > .widget-header {
    font-size: 26px !important;
    margin-top: 10px!important;
    margin-bottom: 5px !important;
}
.h1-728x90 {
    font-size: 28px !important;
    margin-top: 5px;
    margin-bottom: 2px !important;
}
.h3-728x90 {
    font-size: 18px !important;
    font-weight: 500;
}
.h1-160x600 {
    font-size: 27px !important;
    margin-bottom: 2px !important;
}
.h3-160x600 {
    font-size: 24px !important;
}

.widget-sub-heading {
    font-size: 16px !important;
    font-weight: 500;
}

.widget-strap-line h3 {
/*    font-size:25px!important;*/
    margin: 0 0;
    padding: 0 0;
}
.widget-slideshow {
    position: absolute;
    top: 0;
    left: 0;
}
.slick-slide {
    margin-right: 0;
}
.slick-slide img {

    margin-right:0;
}
/*.widget-slide {*/
    /*overflow: hidden;*/

/*}*/
/*.widget-slideshow-image {*/
    /*position:relative;*/
    /*width: 1200px;*/
/*}*/
/*.slide-one {}*/
.widget-location-details-box {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: white;
    width: 203px;
    /*height: 150px;*/
    padding: 12px 13px 8px;
}
.widget-location-details-box h5 {
    font-size: 11px;
    color: #A5A5A5;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing:0.5px;
    margin: 0 0;
    padding: 0 0;
}
.widget-location-details-box h2 {
    font-size: 16px;
    color: #555555;
    line-height: 18px;
    margin: 0 0;
    padding: 0 0;
    font-weight: 500;
    letter-spacing:-0.5px;
}
.widget-price {
    font-size: 32px;
    font-weight:900;
    color:#5fce17;
    letter-spacing: -1px;
    margin: 6px 0 0;
    padding: 0 0;
    line-height: 23px;

}
.widget-price sup {
    top: -0.4em;
    font-size: 60%;
}
.widget-price span {
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    vertical-align: middle;
    letter-spacing: 0;
}
.widget-five-stars {
    width: 75px;
}

.widget-footer {
    position: absolute;

    background: white;
    width: 100%;
    height: 75px;
    padding: 20px 8px;
    z-index: 999;
}
.widget-footer .half-column {
    width: 46%;
    margin: 0 2%;
}
/*@keyframes widget-image {*/
    /*0% {*/
        /*left: 0;*/
    /*}*/
    /*22% {*/
        /*left: 0;*/
    /*}*/
    /*25% {*/
         /*left: -300px;*/
     /*}*/
    /*47% {*/
        /*left: -300px;*/
    /*}*/
    /*50% {*/
        /*left: -600px;*/
    /*}*/
    /*72% {*/
        /*left: -600px;*/
    /*}*/
    /*75% {*/
        /*left: -900px;*/
    /*}*/
    /*97% {*/
        /*left: -900px;*/
    /*}*/
    /*100% {*/
        /*left: 0;*/
    /*}*/
/*}*/

/*.widget-slideshow-image {*/
    /*-webkit-animation: widget-image 15s ease-in-out;*/
    /*-moz-animation: widget-image 15s ease-in-out;*/
    /*-o-animation: widget-image 15s ease-in-out;*/
    /*animation: widget-image 15s ease-in-out;*/
    /*animation-iteration-count: infinite;*/
/*}*/
/*.widget-slideshow-image:hover {*/
    /*-webkit-animation-play-state: paused;*/
    /*-moz-animation-play-state: paused;*/
    /*-o-animation-play-state: paused;*/
    /*animation-play-state: paused;*/
/*}*/