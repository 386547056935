/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.online_republic_widget_container {
    background-color: #40b3e9;
    padding: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 5px
}

.online_republic_widget_container .left {
    display: none
}

@media (min-width: 552px) {
    .online_republic_widget_container .left {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.online_republic_widget_container .left > div {
    -ms-flex: 1;
    flex: 1
}

.online_republic_widget_container .left > div > img {
    height: auto;
    width: 200px
}

.online_republic_widget_container .left h4 {
    font-weight: 700
}

.online_republic_widget_container .left h4, .online_republic_widget_container .left h5 {
    color: #fff;
    text-align: center;
    padding: 30px 0
}

.online_republic_widget_container .right {
    width: 100%;
    max-width: 350px;
    height: 400px;
    border: none
}