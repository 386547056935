.root {
  background-color: #FFB641;
  color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    margin: 18px;
    display: flex;
    align-items: center;

    .action_icon {
      width: 25px;
      height: 25px;
    }

    .text {
      font-weight: 400;
      margin-left: 10px;
    }
  }

  a {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    color: gray;
  }
}
