/*********** MENU *********/


.menu {
    border: #cccccc solid 1px;
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 15px 0;
    background: #fff;
}


.menu-title {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 22px;
}

.menu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}



.mint-bottom-box {
    background: #e6fde2;
    padding: 12px 25px 14px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #9DE56E;
    margin: 0 -10px -10px;
    text-align: center;
}

.side-menu li {
    background: #fff;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 13px 12px;
    border: 1px solid #fff;
    color: #2196F3;
}
.menu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.side-menu li.active {
    background: #f2f2f2;
    border-color:#e1e1e1;
}