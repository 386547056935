.listing-item {
    min-height: 300px;
    padding: 0;
    margin-right: 1%;
    width: 24%;
    transition: opacity 0.3s;
}
.listing-item:hover {
    opacity:0.8;
}
.listing-item a:hover {
    text-decoration: none;
}
.listing-thumbnail {
    overflow: hidden;
    width: auto;
    height: 170px;
    position: relative;
    text-align: center;
    background-size: cover;
}
.listing-item-text {
    padding: 10px 0;

}
.listing-title {
    color: #555;
    font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: -.25px;
    line-height: 18px;
    margin-bottom: 1px;
}

.listing-region-name {
    color: #9F9F9F;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 6px;
}
.listing-price {
    color: #5fce17;
    font-weight: 900;
    font-size: 27px;
    letter-spacing: -0.5px;
    line-height: 20px;
}
.listing-price .price-dollar-symbol {
    font-size: 16px;
    line-height: 26px;
}
.listing-price .price-per-motorhome {
    font-size: 13px;
    vertical-align: bottom;
    font-weight: 500;
    font-style: italic;
}
.listing-rating img {
    width: 16px;
    margin-right: 1px;
}

/* COLLECTIONS */
.collection-thumbnail {
    padding-left: 0;
}
.collection-thumbnail a {
    -webkit-transition-property: top, bottom;
    -webkit-transition-duration: 0.25s;
    position: relative;
    top: 0;
}
.collection-thumbnail a:hover {
    top: -7px;
}


.collections-banner {
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 400px;
    left: 0;
    top: 70px;
    background-position: center;

}

.collections-page{
    margin-top:300px;
    z-index: 99999;
    position: relative;
}

.collections-container{
    background-color: #ffffff;
    padding:65px 100px;
}
.all-collections-container {
    padding-top: 0;
}
.collections-container h2, .collections-container .breadcrumbs {
    padding-left: 15px;
}
.breadcrumbs {
    margin-bottom: 20px;
}

.text-image-block img {
    width: 350px;
    height: auto;
    margin-top: 0px!important;
    margin-right: 20px;
    margin-bottom: 12px;
}

.text-image-block h2{
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 5px;
    padding-left: 0;
}

.text-image-block h3{
    font-size: 20px;
    font-style: italic;
    margin-top: 0px;
    margin-bottom: 10px!important;

}

.collection-listings-block h4{
    font-family: "Museo Sans", "museo-sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size:20px;
    margin-bottom:15px;
}


h4.collection-listing-title {
    font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size:16px;
    text-align:left;
    letter-spacing: -0.5px;
}

.collection-price {
    font-size: 27px;
    font-weight: 900;
    color: #5fce17;
    letter-spacing: -0.5px;

}

.collection-price .price-dollar-symbol {
    font-size: 17px;
}
.collection-price .price-per-motorhome {
    font-size: 12px;
    font-weight: 300;
    vertical-align: middle;
    -webkit-font-smoothing: initial;
    color: #999999;
}

.collection-ratings {
    position: absolute;
    bottom: 4px;
    left: 0;
    /*background: #555;*/
    width: 100%;
    padding: 4px 12px;
    border-radius: 0 0 5px 5px;
}
.collection-ratings img {
    width:15px;
}

.collection-tile{
    background-color:#ffffff;
    border:1px solid #e0e0e0;
    border-radius: 5px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    min-height: 250px;
    width: 24%;
    margin-right: 12px;
    padding-right: 0px;
    padding-left: 0px;
    transition: all 0.3s;

}
.collection-tile:hover {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}

.collection-tile a:hover {
    text-decoration: none!important;
}




@media (max-width: 1200px) {
    .page-content {
       width: 100%;
    }
    .listing-item {
        /*width: 100%;*/
    }
    #locations-map .listing-item {
        width: 32%;
    }
}
@media (min-width: 0px) and (max-width: 1115px) {
    #locations-map .listing-item {
        width: 49%;
    }
}
@media (min-width: 650px) and (max-width: 960px) {
    #locations-map .listing-item {
        min-height: 260px;
    }
    #locations-map .listing-item .listing-thumbnail {
        height: 140px;
    }
}
@media (min-width: 650px) and (max-width: 980px) {
    .listing-item {
        width: 32%;
    }
}
@media (max-width: 650px) {
    .text-image-block img {
        width: 100%;
    }
    .listing-item {
        width: 49%;
    }
}
@media (max-width: 600px) {

    .collections-banner {
        display: none;
    }
    .collections-page{
        margin-top:0px;
    }
    .collections-container h2, .collections-container .breadcrumbs {
        padding-left: 0px;
    }
    .text-image-block img {
        float: left!important;
        margin-left:0!important;
    }
    .collection-listings-block {
        margin: 0 -15px !important;
    }
    .collection-container p {
        color: #555;
        font-size: 17px;
    }

}
@media (max-width: 1200px) {
    .collections-container {
        padding: 30px 50px;
    }
    .all-collections-container {
        padding-top: 0;
    }
}

@media (max-width: 500px) {
    .collections-container {
        padding: 30px 30px;
    }
    .all-collections-container {
        padding-top: 0;
    }
    .text-image-block img {
        width: 100%;
        height: auto;
    }
    .collection-tile {
        width:100%;
        min-height: 250px;
    }
    .location-tile-image {
        height: 150px;
    }
}


@media (max-width: 500px) {
    .listing-item {
        width: 100%;
    }
    .collection-listings-block {
        margin: 0 !important;
    }

    .collection-tile {
        width:100%;
        min-height: 330px;
    }
    .listing-thumbnail {
        height: 210px;
    }

    .collection-thumbnail {
        margin-bottom: 10px
    }
}